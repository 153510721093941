import React from 'react';
import {useEnvironment} from '@wix/yoshi-flow-editor';
import {TPAComponentsProvider} from 'wix-ui-tpa/cssVars';
import {useControllerProps} from '../Widget/ControllerContext';
import {getDirValue} from '../../../domain/utils/getDirValue';
import {Layout} from '../../../common/components/Layout/Layout';
import {Header} from '../Header/Header';
import {Footer} from '../Footer/Footer';
import {Summary} from '../Summary/Summary';

export enum PaymentRequestAppDataHook {
  root = 'CheckoutAppDataHook.root',
  footer = 'CheckoutAppDataHook.footer',
  header = 'CheckoutAppDataHook.header',
  summary = 'CheckoutAppDataHook.summary',
}

const PaymentRequestApp = () => {
  const {failedToFetch, isLoading, isOneColumnView} = useControllerProps();
  const {isRTL} = useEnvironment();

  return (
    <TPAComponentsProvider value={{rtl: isRTL}}>
      <Layout
        dataHook={PaymentRequestAppDataHook.root}
        isLoading={isLoading}
        failedToFetch={failedToFetch}
        isOneColumnView={isOneColumnView}
        dir={getDirValue(isRTL)}
        Header={() => <Header dataHook={PaymentRequestAppDataHook.header} />}
        Aside={() => <Summary dataHook={PaymentRequestAppDataHook.summary} />}
        Footer={() => <Footer dataHook={PaymentRequestAppDataHook.footer} />}>
        {<span>Payment Request App</span>}
      </Layout>
    </TPAComponentsProvider>
  );
};

// eslint-disable-next-line import/no-default-export
export default PaymentRequestApp;
