import React from 'react';
import {st, classes} from './Header.st.css';
import {useControllerProps} from '../Widget/ControllerContext';
import {StoreNameAndLogo} from '../../../common/components/StoreNameAndLogo/StoreNameAndLogo';

export const Header = ({dataHook}: {dataHook: string}) => {
  const {
    paymentRequestSettingsStore: {paymentRequestSettings},
  } = useControllerProps();

  return (
    <header className={classes.header}>
      <div className={st(classes.innerHeader)} data-hook={dataHook}>
        <StoreNameAndLogo logoUrl={paymentRequestSettings.logoUrl} storeName={paymentRequestSettings.storeName} />
      </div>
      <div className={classes.divider} />
    </header>
  );
};
