import React, {FC} from 'react';
import {useTranslation, WidgetProps} from '@wix/yoshi-flow-editor';
import {ControllerContext} from './ControllerContext';
import {LocaleKeysProvider} from '../../../locale-keys/LocaleKeys';
import PaymentRequestApp from '../PaymentRequestApp/PaymentRequestApp';
import {PaymentRequestControllerProps} from '../../../types/paymentRequestApp.types';
import './index.css';

const Widget: FC<WidgetProps<PaymentRequestControllerProps>> = (
  controllerProps: WidgetProps<PaymentRequestControllerProps>
) => {
  const {t} = useTranslation();

  return (
    <ControllerContext value={controllerProps}>
      <LocaleKeysProvider
        translateFn={(key: any, options: any, ...rest: unknown[]) =>
          t(key, {...options, interpolation: {escapeValue: false}, ...rest})
        }>
        <PaymentRequestApp />
      </LocaleKeysProvider>
    </ControllerContext>
  );
};

export default Widget;
