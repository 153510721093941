import React from 'react';
import {Image, Text, TextButton, ImageAspectRatioPresets} from 'wix-ui-tpa/cssVars';
import {useLocaleKeys} from '../../../locale-keys/LocaleKeys';
import {classes} from './StoreNameAndLogo.st.css';

export interface StoreNameAndLogoProps {
  continueShoppingUrl?: string;
  clickOnContinueShopping?: () => void;
  logoUrl?: string;
  storeName?: string;
}

export enum StoreNameAndLogoDataHooks {
  root = 'StoreNameAndLogoDataHooks.root',
  logo = 'StoreNameAndLogoDataHooks.logo',
  storeName = 'StoreNameAndLogoDataHooks.storeName',
  title = 'StoreNameAndLogoDataHooks.title',
}

export const StoreNameAndLogo = ({
  continueShoppingUrl,
  clickOnContinueShopping,
  logoUrl,
  storeName,
}: StoreNameAndLogoProps) => {
  const localKeys = useLocaleKeys();

  return (
    <TextButton
      data-hook={StoreNameAndLogoDataHooks.root}
      className={classes.headerTitleAndLogo}
      contentClassName={classes.headerTitleAndLogoContent}
      as={'a'}
      onClick={() => clickOnContinueShopping?.()}
      href={continueShoppingUrl}>
      {logoUrl && (
        <Image
          data-hook={StoreNameAndLogoDataHooks.logo}
          className={classes.logo}
          src={logoUrl}
          aspectRatio={ImageAspectRatioPresets.square}
          fluid={true}
          alt={localKeys.checkout.page.siteLogo_altTextDefault()}
        />
      )}
      <span>
        {storeName && !logoUrl && (
          <Text className={classes.headerStoreName} data-hook={StoreNameAndLogoDataHooks.storeName}>
            {storeName}
          </Text>
        )}
        <Text className={classes.headerTitle} data-hook={StoreNameAndLogoDataHooks.title}>
          {storeName || logoUrl
            ? localKeys.checkout.header.store_title_suffix()
            : localKeys.checkout.header_default_title()}
        </Text>
      </span>
    </TextButton>
  );
};
