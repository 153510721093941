import React from 'react';
import {classes} from './PaymentRequestItem.st.css';
import {useLocaleKeys} from '../../../../locale-keys/LocaleKeys';
import {ItemLayout} from '../../../../common/components/Item/ItemLayout';
import {ItemName} from '../../../../common/components/Item/ItemName/ItemName';
import {ItemPrice} from '../../../../common/components/Item/ItemPrice/ItemPrice';
import {ItemOptions} from '../../../../common/components/Item/ItemOptions/ItemOptions';
import {MAX_ITEM_OPTION_LENGTH, NUMBER_OF_OPTIONS_TO_SHOW_BEFORE_COLLAPSE} from '../../../../common/constants';
import {ItemImage} from '../../../../common/components/Item/ItemImage/ItemImage';

export enum PaymentRequestItemDataHooks {
  LineItemWrapper = 'PaymentRequestItemDataHooks.LineItemWrapper',
  Name = 'PaymentRequestItemDataHooks.Name',
  ComparePrice = 'PaymentRequestItemDataHooks.ComparePrice',
  Price = 'PaymentRequestItemDataHooks.Price',
  Subscription = 'PaymentRequestItemDataHooks.Subscription',
  Options = 'PaymentRequestItemDataHooks.Options',
  OutOfStock = 'PaymentRequestItemDataHooks.OutOfStock',
  Image = 'PaymentRequestItemDataHooks.Image',
  DefaultImage = 'PaymentRequestItemDataHooks.DefaultImage',
}

export const PaymentRequestItem = ({
  useLayoutForLongPrice,
}: {
  useLayoutForLongPrice: boolean;
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const localeKeys = useLocaleKeys();

  return (
    <li data-hook={PaymentRequestItemDataHooks.LineItemWrapper}>
      <ItemLayout
        descriptionWithColumns={!useLayoutForLongPrice}
        Icon={
          <ItemImage
            imageDataHook={PaymentRequestItemDataHooks.Image}
            defaultImageDataHook={PaymentRequestItemDataHooks.DefaultImage}
          />
        }
        MainDescription={<ItemName name={'Order # 12345'} dataHook={PaymentRequestItemDataHooks.Name} />}
        SecondaryDescription={
          <ItemPrice
            comparePriceDataHook={PaymentRequestItemDataHooks.ComparePrice}
            priceDataHook={PaymentRequestItemDataHooks.Price}
            shortPrice={!useLayoutForLongPrice}
            formattedPrice={'$100.00'}
            priceAriaLabel={localeKeys.checkout.orderSummary.price.ariaLabel({
              price: '$100.00',
            })}
          />
        }
        Details={
          <div className={classes.itemData}>
            <ItemOptions
              dataHook={PaymentRequestItemDataHooks.Options}
              itemId={'12345'}
              options={[{title: '', value: 'Description'}]}
              maxOptionValLength={MAX_ITEM_OPTION_LENGTH}
              collapseSettings={{
                numberOfOptionsToCollapseAfter: NUMBER_OF_OPTIONS_TO_SHOW_BEFORE_COLLAPSE + 1,
                showMoreTitle: localeKeys.checkout.order_summary.items.more_details(),
                showLessTitle: localeKeys.checkout.order_summary.items.lessDetails(),
              }}
            />
          </div>
        }
      />
    </li>
  );
};
